/**
 * Created by Giannis Kallergis on 2018-12-29
 */

import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

/** Models / Types */
import { ArticleNode } from "../propTypes";

/** Components */
import Layout from "../components/Layout";
import Wrapper from "../components/Wrapper";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Subline from "../components/Subline";
import ArticlePreview from "../components/ArticlePreview";
import Content from "../components/Content";
import SectionTitle from "../components/SectionTitle";
import AuthorHeader from "../components/AuthorHeader";

// noinspection JSUnusedGlobalSymbols
export default class CategoryPage extends React.PureComponent {
  static propTypes = {
    pageContext: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
      allMarkdownRemark: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: ArticleNode.isRequired,
          })
        ).isRequired,
      }),
    }).isRequired,
  };

  /** Renderers */

  _renderArticlePreview = article => {
    return (
      <ArticlePreview
        title={article.node.frontmatter.title}
        date={article.node.frontmatter.date}
        tldr={article.node.frontmatter.tldr}
        timeToRead={article.node.fields.timeToRead}
        slug={article.node.fields.slug}
        category={article.node.frontmatter.category}
        tags={article.node.frontmatter.tags}
        key={article.node.fields.slug}
      />
    );
  };

  render() {
    const { pageContext } = this.props;
    const title = pageContext.name;
    const slug = pageContext.slug;
    const articles = this.props.data.allMarkdownRemark.edges;
    const totalCount = articles.length;
    const subline = `${totalCount} article${totalCount === 1 ? "" : "s"} in "${title}" category`;
    return (
      <Layout>
        <SEO isPost slug={slug} title={title} />
        <Wrapper>
          <Header>
            <AuthorHeader />
          </Header>
          <Content hasHeader={true}>
            <SectionTitle>Category &ndash; {title}</SectionTitle>
            <Subline sectionTitle>
              {subline}
              <br />
              (See <Link to="/categories">all categories</Link>)
            </Subline>
            {articles.map(this._renderArticlePreview)}
          </Content>
        </Wrapper>
      </Layout>
    );
  }
}

// noinspection JSUnusedGlobalSymbols
export const pageQuery = graphql`
  query CategoryArticles($name: String!) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date] }
      filter: { frontmatter: { category: { eq: $name }, draft: { ne: true } } }
    ) {
      edges {
        node {
          ...ArticleInfo
        }
      }
    }
  }
`;
